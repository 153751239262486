class OTF_Main_JS{
    constructor(){
        this.initPortfolio();
        if ($('body [otf-count-number]').length > 0) {
            this.initCounter();
        }
    }

    initPortfolio(){
        $('.portfolio-popup-gallery').magnificPopup({
            delegate : 'a',
            type     : 'image',
            tLoading : 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery  : {
                enabled           : true,
                navigateByImgClick: true,
                preload           : [0, 1]
            },
            image    : {
                tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            }
        });

    }

    initCounter() {
        $('[opal-counter]').data('countToOptions', {
            formatter: function (value, options) {
                return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
            }
        }).each((options, element) =>{
            let $this = $(element);
            options = {...options || {}, ...$this.data('countToOptions') || {}};
            $this.countTo(options);
        });
    }
}

$(document).ready(() => {
    new OTF_Main_JS();
})